import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import useHzaSync from '../../../V2/features/hzaSync/useHzaSync';
import { biPropsSelector } from '../../../V2/features/header/redux/selectors';
import { embeddedUrlSelector } from '../../../V2/features/powerBi/redux/selectors';
import { QtmTab, QtmTabs } from '@qtm/react';
import { setReportDefaultPage } from '../../../V2/features/powerBi/hooks/useReportRendering';

const StyledTabsContainer = styled(Box)(() => ({
  position: 'relative',
  height: 'inherit',
  'tds-tabs': {
    height: 'inherit',
  },
}));

const TABS = {
  COMPETITIVENESS: {
    name: 'Competitiveness',
    slug: '',
    disabled: true,
    id: 'competitiveness',
  },
  OPERATING_MODEL: {
    name: 'Operating Model',
    slug: 'operating-model',
    disabled: false,
    id: 'competitiveness-operating-model',
  },
  CRAFTMANSHIP: {
    name: 'Craftnamship',
    slug: 'craftnamship',
    disabled: true,
    id: 'competitiveness-craftmanship',
  },
  DIGITAL_TRANSFORMATION: {
    name: 'Digital Transformation',
    slug: 'digital-transformation',
    disabled: true,
    id: 'competitiveness-digital-transformation',
  },
  TALENTS_AND_CULTURE: {
    name: 'Talents & Culture',
    slug: 'talents-and-culture',
    disabled: false,
    id: 'competitiveness-talents-and-culture',
  },
};

export default function EngineeringCompetitivenessMenu({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const hzaSync = useHzaSync();
  const embeddedUrl = useSelector(embeddedUrlSelector);
  const biProps = useSelector(biPropsSelector);
  const [show, setShow] = useState(false);
  const [selectedReport, setSelectedReport] = useState(params.report);

  useEffect(() => {
    if (/\/engineeringcompetitivness\/(.*)/.test(location.pathname)) {
      return setShow(true);
    }

    return setShow(false);
  }, [location]);

  useEffect(() => {
    setSelectedReport(params.report);
  }, [params]);

  // const handleClick = eventName => {
  //   setSelected(false);
  //   let filtersSet = false;
  //
  //   if ([EVENTS.OPERATING_MODEL, EVENTS.TALENTS_AND_CULTURE].includes(eventName)) {
  //     dispatch(setIsHza(false));
  //   }
  //   const filters = store.getState().powerBi.report_filters;
  //
  //   window.report.setFilters(filters);
  //
  //   if (eventName === EVENTS.OPERATING_MODEL) {
  //     dispatch(setEmbeddedUrl(biProps.list.data.wfbC2URL));
  //   } else {
  //     if (embeddedUrl !== biProps.list.data.skillsFocusC2Url) {
  //       dispatch(setEmbeddedUrl(biProps.list.data.skillsFocusC2Url));
  //     }
  //   }
  //
  //   if (EVENTS.TALENTS_AND_CULTURE === eventName) {
  //     dispatch(setSelectedReport(31));
  //   }
  //
  //   if (EVENTS.COMPETITIVENESS === eventName) {
  //     hzaSync.showBiC3("engineeringCompetitivnessC3Url");
  //   } else {
  //     publish(eventName);
  //   }
  // };

  const handleClick = async reportPage => {
    await setReportDefaultPage();
    navigate({
      pathname: `/engineeringcompetitivness/${reportPage}`,
    });
  };

  const isSelected = reportPage => {
    if (selectedReport === reportPage) {
      return 'qtm-active';
    }

    return '';
  };

  return (
    <StyledTabsContainer className={className}>
      {show && (
        <>
          <QtmTabs scrollable fullHeight scrollHorizontally>
            {Object.keys(TABS).map(key => (
              <QtmTab
                key={key}
                classes={isSelected(TABS[key].slug)}
                disabled={TABS[key].disabled}
                onClickedTabEvent={() => handleClick(TABS[key].slug)}
              >
                {TABS[key].name}
              </QtmTab>
            ))}
          </QtmTabs>
        </>
      )}
    </StyledTabsContainer>
  );

  // return (
  //   <StyledTabsContainer className={className}>
  //     {show && (
  //       <>
  //         {selectedReport}
  //         <TdsTabs scrollable scrollHorizontally={true}>
  //           <TdsTab disabled onClickedTabEvent={() => handleClick("competitiveness")}>
  //             Competitiveness
  //           </TdsTab>
  //           <TdsTab
  //             classes={isSelected(REPORTS.OPERATING_MODEL)}
  //             onClickedTabEvent={() => handleClick("operating-model")}
  //           >
  //             Operating Model
  //           </TdsTab>
  //           <TdsTab disabled>Craftmanship</TdsTab>
  //           <TdsTab disabled>Digital Transformation</TdsTab>
  //           <TdsTab
  //             classes={isSelected("talents-and-culture")}
  //             onClickedTabEvent={() => handleClick("talents-and-culture")}
  //           >
  //             Talents & Culture
  //           </TdsTab>
  //         </TdsTabs>
  //       </>
  //     )}
  //   </StyledTabsContainer>
  // );

  // return (
  //   <StyledTabsContainer className={className}>
  //     {show && (
  //       <>
  //         <TdsTabs scrollable scrollHorizontally={true}>
  //           <TdsTab disabled onClickedTabEvent={() => handleClick(EVENTS.COMPETITIVENESS)}>
  //             Competitiveness
  //           </TdsTab>
  //           <TdsTab
  //             classes={selected ? 'qtm-active' : ''}
  //             onClickedTabEvent={() => handleClick(EVENTS.OPERATING_MODEL)}
  //           >
  //             Operating Model
  //           </TdsTab>
  //           <TdsTab disabled>Craftmanship</TdsTab>
  //           <TdsTab disabled>Digital Transformation</TdsTab>
  //           <TdsTab onClickedTabEvent={() => handleClick(EVENTS.TALENTS_AND_CULTURE)}>
  //             Talents & Culture
  //           </TdsTab>
  //         </TdsTabs>
  //       </>
  //     )}
  //   </StyledTabsContainer>
  // );
}
