import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  orgDetailsSelector,
  perspectiveSelector,
  schemaSelector,
} from '../../../../redux/selectors';
import { EmwButton } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import { deleteOrganizationApi } from '../../../../redux/api';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { DiagramContext } from '../../../Diagram/context/DiagramContext';
import {
  GENERIC_GROUP_ID,
  ORGANIZATION_PERSPECTIVE,
  ORGANIZATION_TYPE,
} from '../../../../constants';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';
import { getUser } from '../../../../../header/redux/actions';

export default function DeleteBtn({ btn, id, name, parentCountryId, type }) {
  const dispatch = useDispatch();
  const diagramContext = useContext(DiagramContext);
  const schema = useSelector(schemaSelector);
  const orgDetails = useSelector(orgDetailsSelector);
  const perspective = useSelector(perspectiveSelector);
  const snackbar = useAppSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSaveClickYes = async () => {
    setIsLoading(true);
    const response = await deleteOrganizationApi(id);

    const payload = {
      id: schema.path.selectedBL.id,
      type: schema.path.selectedBL.type,
    };

    if (orgDetails.list.data.cardDetails.type === ORGANIZATION_TYPE.GBU) {
      payload.id = GENERIC_GROUP_ID;
      payload.type = ORGANIZATION_PERSPECTIVE.BY_GBU
        ? ORGANIZATION_TYPE.GROUP
        : ORGANIZATION_TYPE.GROUP_COUNTRY;
    }

    if (orgDetails.list.data.cardDetails.type === ORGANIZATION_TYPE.BL) {
      payload.id = schema.path.selectedGBU.id;
      payload.type = schema.path.selectedGBU.type;
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      await diagramContext.fetchAndSelectNode(
        schema.path.selectedBL.id,
        'Domain',
        null,
        null,
        parentCountryId
      );
    } else {
      await diagramContext.fetchAndSelectNode2(payload);
    }

    setIsLoading(false);
    snackbar.show(response.message, response.messageType);
    setIsOpen(false);
    return dispatch(getUser());
  };

  return (
    <>
      <Grid item ml={0.5}>
        <EmwButton
          variant="ghost"
          size="small"
          id="btnDelete"
          title="Delete"
          disabled={!btn.enabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          <TdsIcon icon="delete" variant="outlined" />
        </EmwButton>
      </Grid>
      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          callback={onSaveClickYes}
          actionButton="Delete"
          title={`Do you want to delete the Competence Center: ${name}?`}
          text="This action cannot be undone."
          icon="warning_amber"
          classes="close-icon"
          loading={isLoading}
        />
      )}
    </>
  );
}
