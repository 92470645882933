import React, { useState, useEffect, useContext } from 'react';
import { Divider, Grid } from '@mui/material';
import { TdsIcon } from '@tds/react';
import OrganizationCardContextProvider from '../../../OrganizationCard/context/OrganizationCardContextProvider';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { QtmMultiselect } from '@qtm/react';
import { useDispatch, useSelector } from 'react-redux';
import { filterCCTypeSelector, schemaSelector } from '../../../../redux/selectors';
import { changeCCType } from '../../../../redux/actions';
import { ORGANIZATION_CC_FILTER as options } from '../../../../constants';

export default function DiagramHeaderItem({ item }) {
  const orgFilter = useSelector(filterCCTypeSelector);
  const dispatch = useDispatch();
  const allFilters = options.map(item => item.value);
  const [filterValues, setFilterValues] = useState(orgFilter);
  const schema = useSelector(schemaSelector);

  useEffect(() => {
    if (document.getElementById(schema.path.selectedBL.code)) {
      document.getElementById(schema.path.selectedBL.code).click();
    }
  }, [filterValues]);

  const onFilterChange = async event => {
    const selectedOptions = event.detail.selectedOptions;
    let newFilters = [];
    if (selectedOptions.length === 0) {
      newFilters = [];
      setFilterValues([]);
    } else if (selectedOptions.includes('ALL')) {
      setFilterValues(prevValues => {
        if (prevValues && !prevValues.includes('ALL')) {
          newFilters = allFilters;
          return allFilters;
        } else {
          const optionsToDeselect = selectedOptions.filter(item => item !== 'ALL');
          newFilters = [...optionsToDeselect];
          return newFilters;
        }
      });
    } else {
      setFilterValues(prevValues => {
        if (prevValues && prevValues.includes('ALL') && !selectedOptions.includes('ALL')) {
          newFilters = [];
          return [];
        } else {
          const optionsToDeselect = selectedOptions.filter(item => item !== 'ALL');
          newFilters = [...optionsToDeselect];
          return newFilters;
        }
      });
    }
    dispatch(changeCCType(selectedOptions));
  };

  return (
    <Grid item className="diagram-headings-unit" key={item.code}>
      <Grid container className="justify-between items-center h-l">
        <EmwTypography component="body-2" fontWeight="bold">
          {item.name}
        </EmwTypography>

        {item.type === 'CC' && (
          <QtmMultiselect
            title='CC Type'
            size="small"
            placeholder="Selected: All"
            options={options}
            onValueChanged={onFilterChange}
            classes={'filter-cc-types-dropdown'}
            value={filterValues}
          ></QtmMultiselect>
        )}

        {item.canAddCC && <OrganizationCardContextProvider></OrganizationCardContextProvider>}
      </Grid>

      <Divider className="divider" />

      {item.selectedUnit && (
        <Grid title={item.selectedUnit} container alignItems="center">
          <EmwTypography classes="selected-unit" fontSize="7" fontWeight="bold">
            {item.selectedUnit}
          </EmwTypography>
          <TdsIcon icon="keyboard_arrow_right" size={'small'} />
        </Grid>
      )}
    </Grid>
  );
}
