import React, { createContext } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { cloneDeep } from 'lodash';
import deepdash from 'deepdash';
import moment from 'moment/moment';
import {
  DATE_TIME_FORMAT,
  ORGANIZATION_CARD_MODAL_TYPE,
  ORGANIZATION_PERSPECTIVE,
  ORGANIZATION_TYPE,
} from '../../../constants';
import {
  newOrgDataSelector,
  orgDetailsSelector,
  perspectiveSelector,
  schemaSelector,
} from '../../../redux/selectors';
import { DiagramContext } from '../../Diagram/context/DiagramContext';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import { updateOrganizationApi } from '../../../redux/api';
import { setNewOrgData, setOrgDetails } from '../../../redux/actions';
import { isEqual } from 'lodash';
import { removeCountryCode } from '../../../../../utils/countryUtils';
import { getUser } from '../../../../header/redux/actions';
import { ENGINEER_TYPE } from '../../../constants';

deepdash(_);
export const OrganizationCardContext = createContext({});

// TODO: build a generator function to return default data for each new object
const newDelivery = {
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
  id: null,
  name: {
    value: null,
    enabled: false,
    visible: true,
  },
  date: {
    value: null,
    enabled: false,
    visible: true,
  },
  endDate: {
    value: null,
    enabled: false,
    visible: true,
  },
  visible: true,
  leader: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: null,
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: true,
      visible: true,
    },
    endDate: {
      value: null,
      enabled: true,
      visible: true,
    },
    visible: false,
  },
  managers: [
    {
      btnAdd: { value: null, enabled: true, visible: true },
      btnEdit: { value: null, enabled: true, visible: true },
      id: null,
      visible: true,
      type: ENGINEER_TYPE.EDM.label,
      managers: [
        {
          btnAdd: { value: null, enabled: true, visible: true },
          btnEdit: { value: null, enabled: true, visible: true },
          id: null,
          name: {
            value: '',
            enabled: false,
            visible: true,
          },
          type: {
            value: 0,
            enabled: false,
            visible: true,
          },
          startDate: {
            value: null,
            enabled: false,
            visible: true,
          },
          endDate: {
            value: null,
            enabled: false,
            visible: true,
          },
          visible: false,
        },
      ],
    },
    {
      btnAdd: { value: null, enabled: true, visible: true },
      btnEdit: { value: null, enabled: true, visible: true },
      id: null,
      visible: true,
      type: ENGINEER_TYPE.WPM.label,
      managers: [
        {
          btnAdd: { value: null, enabled: true, visible: true },
          btnEdit: { value: null, enabled: true, visible: true },
          id: null,
          name: {
            value: '',
            enabled: false,
            visible: true,
          },
          type: {
            value: 0,
            enabled: false,
            visible: true,
          },
          startDate: {
            value: null,
            enabled: false,
            visible: true,
          },
          endDate: {
            value: null,
            enabled: false,
            visible: true,
          },
          visible: false,
        },
      ],
    },
  ],
  solutions: [
    {
      id: null,
      visible: true,
      name: {
        value: null,
        enabled: false,
        visible: true,
      },
      date: {
        value: null,
        enabled: false,
        visible: true,
      },
      endDate: {
        value: null,
        enabled: false,
        visible: true,
      },
      leader: {
        btnAdd: { value: null, enabled: true, visible: true },
        btnEdit: { value: null, enabled: true, visible: true },
        id: null,
        name: {
          value: '',
          enabled: false,
          visible: true,
        },
        type: {
          value: 0,
          enabled: false,
          visible: true,
        },
        startDate: {
          value: null,
          enabled: false,
          visible: true,
        },
        endDate: {
          value: null,
          enabled: false,
          visible: true,
        },
        visible: false,
      },
      pm: {
        btnAdd: { value: null, enabled: true, visible: true },
        btnEdit: { value: null, enabled: true, visible: true },
        id: null,
        name: {
          value: null,
          enabled: false,
          visible: true,
        },
        type: {
          value: 0,
          enabled: false,
          visible: true,
        },
        startDate: {
          value: null,
          enabled: false,
          visible: false,
        },
        endDate: {
          value: null,
          enabled: false,
          visible: false,
        },
        visible: false,
      },
      da: {
        btnAdd: { value: null, enabled: true, visible: true },
        btnEdit: { value: null, enabled: true, visible: true },
        id: null,
        name: {
          value: null,
          enabled: false,
          visible: true,
        },
        type: {
          value: 0,
          enabled: false,
          visible: true,
        },
        startDate: {
          value: null,
          enabled: false,
          visible: true,
        },
        endDate: {
          value: null,
          enabled: false,
          visible: true,
        },
        visible: false,
      },
      pda: {
        btnAdd: { value: null, enabled: true, visible: true },
        btnEdit: { value: null, enabled: true, visible: true },
        id: null,
        name: {
          value: null,
          enabled: false,
          visible: true,
        },
        type: {
          value: 0,
          enabled: false,
          visible: true,
        },
        startDate: {
          value: null,
          enabled: false,
          visible: true,
        },
        endDate: {
          value: null,
          enabled: false,
          visible: true,
        },
        visible: false,
      },
      wpms: [
        {
          id: null,
          visible: true,
          name: {
            value: null,
            enabled: false,
            visible: true,
          },
          date: {
            value: null,
            enabled: false,
            visible: true,
          },
          endDate: {
            value: null,
            enabled: false,
            visible: true,
          },
          leader: {
            btnAdd: { value: null, enabled: true, visible: true },
            btnEdit: { value: null, enabled: true, visible: true },
            id: null,
            name: {
              value: null,
              enabled: false,
              visible: true,
            },
            type: {
              value: 0,
              enabled: false,
              visible: true,
            },
            startDate: {
              value: null,
              enabled: false,
              visible: false,
            },
            endDate: {
              value: null,
              enabled: false,
              visible: false,
            },
            visible: false,
          },
          btnAdd: { value: null, enabled: true, visible: true },
          btnEdit: { value: null, enabled: true, visible: true },
        },
      ],

      btnAdd: { value: null, enabled: true, visible: true },
      btnEdit: { value: null, enabled: true, visible: true },
    },
  ],
};

const newSolution = {
  id: null,
  visible: true,
  name: {
    value: null,
    enabled: false,
    visible: true,
  },
  date: {
    value: null,
    enabled: false,
    visible: true,
  },
  endDate: {
    value: null,
    enabled: false,
    visible: true,
  },
  leader: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: '',
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: true,
      visible: true,
    },
    endDate: {
      value: null,
      enabled: true,
      visible: true,
    },
    visible: false,
  },
  pm: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: null,
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    endDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    visible: false,
  },
  da: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: null,
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    endDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    visible: false,
  },
  pda: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: null,
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    endDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    visible: false,
  },
  wpms: [
    {
      id: null,
      visible: true,
      name: {
        value: null,
        enabled: false,
        visible: true,
      },
      date: {
        value: null,
        enabled: false,
        visible: true,
      },
      endDate: {
        value: null,
        enabled: false,
        visible: true,
      },
      leader: {
        btnAdd: { value: null, enabled: true, visible: true },
        btnEdit: { value: null, enabled: true, visible: true },
        id: null,
        name: {
          value: null,
          enabled: false,
          visible: true,
        },
        type: {
          value: 0,
          enabled: false,
          visible: true,
        },
        startDate: {
          value: null,
          enabled: false,
          visible: false,
        },
        endDate: {
          value: null,
          enabled: false,
          visible: false,
        },
        visible: false,
      },
      btnAdd: { value: null, enabled: true, visible: true },
      btnEdit: { value: null, enabled: true, visible: true },
    },
  ],
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
};

const neWorkPackage = {
  id: null,
  visible: true,
  name: {
    value: null,
    enabled: false,
    visible: true,
  },
  date: {
    value: null,
    enabled: false,
    visible: true,
  },
  endDate: {
    value: null,
    enabled: false,
    visible: true,
  },
  leader: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: null,
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    endDate: {
      value: null,
      enabled: false,
      visible: false,
    },
    visible: false,
  },
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
};

const newDiscipline = {
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
  id: null,
  baseName: '',
  name: {
    value: null,
    enabled: false,
    visible: true,
  },
  date: {
    value: null,
    enabled: false,
    visible: true,
  },
  endDate: {
    value: null,
    enabled: false,
    visible: true,
  },
  comment: {
    value: null,
    enabled: false,
    visible: false,
  },
  visible: true,
  leader: {
    btnAdd: { value: null, enabled: true, visible: true },
    btnEdit: { value: null, enabled: true, visible: true },
    id: null,
    name: {
      value: null,
      enabled: false,
      visible: true,
    },
    type: {
      value: 0,
      enabled: false,
      visible: true,
    },
    startDate: {
      value: null,
      enabled: false,
      visible: true,
    },
    endDate: {
      value: null,
      enabled: false,
      visible: true,
    },
    visible: false,
  },
  engineers: [],
};

export default function OrganizationCardContextProvider({ children }) {
  const organization = useSelector(orgDetailsSelector);
  const schema = useSelector(schemaSelector);
  const diagramContext = useContext(DiagramContext);
  const newOrgData = useSelector(newOrgDataSelector);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const [data, setData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [type, setType] = useState('');
  const initialData = cloneDeep(organization);
  const perspective = useSelector(perspectiveSelector);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [focusedItem, setFocusedItem] = useState();
  const GROUP_NODE_ID = '00053af6-7fba-4db8-8572-2d7e23332b6e';

  useEffect(() => {
    if (!type) {
      return;
    }

    if (type === ORGANIZATION_CARD_MODAL_TYPE.EDIT) {
      const editData = cloneDeep(organization.list.data);
      return setData(editData);
    } else {
      setData(null);
    }
  }, [type, organization.list]);

  const noChanges = () => {
    return isEqual(initialData.list.data, data);
  };

  const hasReservedName = name => {
    const reservedNameRegex = /(Delivery|Solution\/Project|Work package|Discipline) [0-9]+/;
    return reservedNameRegex.test(name);
  };

  const removeReservedNames = data => {
    if (data.section3.disciplines) {
      data.section3.disciplines.map(discipline => {
        if (hasReservedName(discipline.name.value)) {
          discipline.name.value = '';
        }
        return discipline;
      });
    }
    if (data.section2.deliveries) {
      _.mapKeysDeep(data.section2.deliveries, (value, key, parentObject) => {
        if (key === 'name' && value && hasReservedName(value.value)) {
          value.value = '';
        }
      });
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      data.cardDetails.blCode = removeCountryCode(data.cardDetails.blCode);
      data.cardDetails.buCode = removeCountryCode(data.cardDetails.buCode);
    }

    return data;
  };

  const submit = async parentCountryId => {
    const response = await updateOrganizationApi(removeReservedNames(data));
    const payloads = [];
    const warningMessages = [];
    snackbar.show(response.message, response.messageType);

    if (response.hasOwnProperty('warningMessage')) {
      response.warningMessages.map(message => {
        warningMessages.push(message);
        warningMessages.push('\n');
      });
    }

    if (warningMessages.length > 0) {
      snackbar.show(warningMessages, 'warning');
    }

    if (response.messageType === 'Error') {
      return;
    }

    setFormModalOpen(false);
    dispatch(setOrgDetails({ list: { data: response.data }, error: null, loading: false }));
    dispatch(getUser());

    // GROUP / GBU / BL / CC
    if (perspective === ORGANIZATION_PERSPECTIVE.BY_GBU) {
      const payloadFetchNode = { id: '', type: '', blCode: '' };
      const payloadSelectNode = { id: '', type: '', blCode: '' };
      if (response.data.cardDetails.type === ORGANIZATION_TYPE.GROUP) {
        payloadFetchNode.id = GROUP_NODE_ID;
        payloadFetchNode.type = ORGANIZATION_TYPE.GROUP;
        await diagramContext.fetchAndSelectNode2(payloadFetchNode);
        return;
      }

      if (response.data.cardDetails.type === ORGANIZATION_TYPE.GBU) {
        payloadFetchNode.id = GROUP_NODE_ID;
        payloadFetchNode.type = ORGANIZATION_TYPE.GROUP;

        if (response.data.cardDetails.isExpired) {
          await diagramContext.fetchAndSelectNode2(payloadFetchNode);
          return;
        }

        payloadSelectNode.id = response.data.cardDetails.id;
        payloadSelectNode.type = response.data.cardDetails.type;
      }

      if (response.data.cardDetails.type === ORGANIZATION_TYPE.BL) {
        payloadFetchNode.id = schema.path.selectedGBU.id;
        payloadFetchNode.type = ORGANIZATION_TYPE.GBU;

        if (response.data.cardDetails.isExpired) {
          await diagramContext.fetchAndSelectNode2(payloadFetchNode);
          return;
        }

        payloadSelectNode.id = response.data.cardDetails.id;
        payloadSelectNode.type = response.data.cardDetails.type;
      }

      if (response.data.cardDetails.type === ORGANIZATION_TYPE.CC) {
        payloadFetchNode.id = schema.path.selectedBL.id;
        payloadFetchNode.type = ORGANIZATION_TYPE.BL;

        if (response.data.cardDetails.isExpired) {
          await diagramContext.fetchAndSelectNode2(payloadFetchNode);
          return;
        }

        payloadSelectNode.id = response.data.cardDetails.id;
        payloadSelectNode.type = response.data.cardDetails.type;
        payloadSelectNode.blCode = response.data.cardDetails.blCode;
      }

      payloads.push(payloadFetchNode, payloadSelectNode);
    }

    // GROUP_COUNTRY / REGION / COUNTRY / CBU / DOMAIN / CC
    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      if (response.data.cardDetails.type === ORGANIZATION_TYPE.GROUP_COUNTRY) {
        const payload = {
          id: '00053af6-7fba-4db8-8572-2d7e23332b6e',
          type: response.data.cardDetails.type,
        };

        if (response.data.cardDetails.isExpired) {
          return await diagramContext.fetchAndSelectNode2(payload);
        }

        payloads.push(payload);
      }

      if (response.data.cardDetails.type === ORGANIZATION_TYPE.COUNTRY) {
        const payload = {
          id: response.data.cardDetails.id,
          type: response.data.cardDetails.type,
        };

        if (response.data.cardDetails.isExpired) {
          return await diagramContext.fetchAndSelectNode2(payload);
        }

        payloads.push(payload);
      }

      if (response.data.cardDetails.type === ORGANIZATION_TYPE.CC) {
        const domainPayload = {
          id: schema.path.selectedBL.id,
          type: ORGANIZATION_TYPE.DOMAIN,
          parentCountryId,
        };

        const ccPayload = {
          id: response.data.cardDetails.id,
          type: response.data.cardDetails.type,
          blCode: response.data.cardDetails.blCode,
          parentCountryId,
        };

        if (response.data.cardDetails.isExpired) {
          return await diagramContext.fetchAndSelectNode2(domainPayload);
        }

        payloads.push(domainPayload);
        payloads.push(ccPayload);
      }
    }

    for (let i = 0; i < payloads.length; i++) {
      await diagramContext.fetchAndSelectNode2(payloads[i]);
    }

    return dispatch(getUser());
  };

  /**
   * @deprecated will de replace by updateName2
   * @param countryCode
   * @param baseName
   */
  const updateName = (countryCode, baseName) => {
    const editData = cloneDeep(data);
    editData.cardDetails.countryCode = countryCode;
    editData.section1.baseName = baseName;

    setData(editData);
  };

  const updateDescription = (abbreviation, description) => {
    const editData = cloneDeep(data);
    editData.section1.abbreviation.value = abbreviation;
    editData.section1.description.value = description;

    setData(editData);
  };

  const updateName2 = cardName => {
    const editData = cloneDeep(data);

    Object.keys(cardName).forEach(key => {
      if (key === 'countryCode') {
        editData.cardDetails[key] = cardName[key];
      } else {
        editData.section1[key].value = cardName[key];
      }
    });

    setData(editData);
  };

  const updatePrefix = prefixName => {
    const editData = cloneDeep(data);
    editData.section1.prefixName = prefixName;

    setData(editData);
  };

  const updateCard = async (section, key, value) => {
    const editData = cloneDeep(data);

    editData[section][key] = value;

    setData(editData);
  };

  const updateCardDates = (key, value) => {
    const editData = cloneDeep(data);

    editData.section1[key].value = moment(value).format(DATE_TIME_FORMAT);
    setData(editData);
  };

  const reset = () => {
    if (type === ORGANIZATION_CARD_MODAL_TYPE.EDIT) {
      setData(initialData.list.data);
    } else {
      const _data = { ...newOrgData };
      dispatch(setNewOrgData(null));
      dispatch(setNewOrgData(_data));
      setData(_data);
    }
  };

  const updateData = (section, propKey, currentItem, updatedItem, index, isNew) => {
    const editData = cloneDeep(data);

    if (index > -1) {
      if (propKey === 'sectionCer') {
        editData[section][propKey].cers[index] = updatedItem;
      }
      if (propKey === 'otherReferents') {
        if (isNew) {
          editData[section][propKey].push(updatedItem);
        } else {
          editData[section][propKey][index] = updatedItem;
        }
      } else {
        editData[section][propKey][index] = updatedItem;
      }
    } else {
      if (Array.isArray(editData[section][propKey])) {
        editData[section][propKey].push(updatedItem);
      } else {
        editData[section][propKey] = updatedItem;
      }
    }

    setData(editData);
  };

  const shouldBeOpen = (collection, key) => {
    const found = _.findDeep(cloneDeep(collection), (value, field) => {
      if (field === 'key' && key) {
        return value === key;
      }
    });

    return Boolean(found);
  };

  const hasHighlightedItem = (collection, unId) => {
    const found = _.findDeep(cloneDeep(collection), (value, field) => {
      if (field === 'unId' && unId) {
        return value === unId;
      }
    });

    return Boolean(found);
  };

  const hasHighlightedItemInPath = (collection, tgis) => {
    if (tgis) {
      let found = false;
      tgis.forEach(tgi => {
        found = _.findDeep(cloneDeep(collection), (value, field) => {
          if (field === 'tgi' && tgi) {
            return value === tgi;
          }
        });
      });

      return Boolean(found);
    }
  };

  const addNewDelivery = () => {
    const editData = cloneDeep(data);
    editData.section2.deliveries = [...editData.section2.deliveries].map(delivery => {
      return {
        ...delivery,
        isNew: false,
      };
    });

    newDelivery.isNew = true;
    // newDelivery.isOpen = true;

    editData.section2.deliveries.push(newDelivery);
    setData(editData);
  };

  const updateDelivery = (delivery, index) => {
    const editData = cloneDeep(data);

    editData.section2.deliveries[index] = delivery;
    setData(editData);
  };

  const updateDeliveryManager = (manager, deliveryIndex, parentIndex, managerIndex, isNew) => {
    const editData = cloneDeep(data);
    if (isNew) {
      editData.section2.deliveries[deliveryIndex].managers[parentIndex].managers.push(manager);
    } else {
      editData.section2.deliveries[deliveryIndex].managers[parentIndex].managers[managerIndex] =
        manager;
    }
    setData(editData);
  };

  const addNewSolution = deliveryIndex => {
    const editData = cloneDeep(data);

    newSolution.btnEdit = editData.section2.deliveries[deliveryIndex].solutions[0].btnEdit;
    editData.section2.deliveries[deliveryIndex].solutions.push(newSolution);
    setData(editData);
  };

  const updateSolution = (solution, deliveryIndex, solutionIndex) => {
    const editData = cloneDeep(data);

    editData.section2.deliveries[deliveryIndex].solutions[solutionIndex] = solution;
    setData(editData);
  };

  const addNewWorkPackage = (deliveryIndex, solutionIndex) => {
    const editData = cloneDeep(data);

    neWorkPackage.btnEdit =
      editData.section2.deliveries[deliveryIndex].solutions[solutionIndex].wpms[0].btnEdit;
    editData.section2.deliveries[deliveryIndex].solutions[solutionIndex].wpms.push(neWorkPackage);
    setData(editData);
  };

  const updateWorkPackage = (workPackage, deliveryIndex, solutionIndex, index) => {
    const editData = cloneDeep(data);

    editData.section2.deliveries[deliveryIndex].solutions[solutionIndex].wpms[index] = workPackage;
    setData(editData);
  };

  const addNewDiscipline = () => {
    const editData = cloneDeep(data);
    editData.section3.disciplines = [...editData.section3.disciplines].map(discipline => {
      return {
        ...discipline,
        isNew: false,
      };
    });

    newDiscipline.isNew = true;
    editData.section3.disciplines.push(newDiscipline);
    setData(editData);
  };

  const updateDiscipline = (discipline, index) => {
    const editData = cloneDeep(data);

    editData.section3.disciplines[index] = discipline;
    setData(editData);
  };

  const getOrgType = () => {
    const selectedType = organization.list.data.cardDetails.type;
    let orgType = '';

    if (selectedType === ORGANIZATION_TYPE.GROUP) {
      orgType = ORGANIZATION_TYPE.BU;
    }

    if ([ORGANIZATION_TYPE.GBU, ORGANIZATION_TYPE.CBU].includes(selectedType)) {
      orgType = ORGANIZATION_TYPE.BL;
    }

    if ([ORGANIZATION_TYPE.BL, ORGANIZATION_TYPE.DOMAIN].includes(selectedType)) {
      orgType = ORGANIZATION_TYPE.CC;
    }

    return orgType;
  };

  return (
    <OrganizationCardContext.Provider
      value={{
        data,
        setData,
        setNewData,
        type,
        setType,
        getOrgType,
        formModalOpen,
        focusedItem,
        submit,
        noChanges,
        updateName,
        updateDescription,
        updateName2,
        updatePrefix,
        updateCard,
        updateCardDates,
        updateData,
        reset,
        shouldBeOpen,
        hasHighlightedItem,
        addNewDelivery,
        updateDelivery,
        updateDeliveryManager,
        addNewSolution,
        updateSolution,
        addNewWorkPackage,
        updateWorkPackage,
        addNewDiscipline,
        updateDiscipline,
        setFormModalOpen,
        hasReservedName,
        setFocusedItem,
        hasHighlightedItemInPath,
      }}
    >
      {children}
    </OrganizationCardContext.Provider>
  );
}
