import { useEffect, useLayoutEffect } from 'react';
import { OPERATING_MODEL, MOOD_AND_CONFIDENCE, TALENTS_AND_CULTURES } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { reportFiltersSelector } from '../redux/selectors';
import { FiltersOperations } from 'powerbi-models';
import { HZA_IFRAME_ID } from '../../hzaSync/constants';
import { setReportFilters } from '../redux/actions';

export const setReportDefaultPage = async () => {
  const DEFAULT_PAGES = {
    [MOOD_AND_CONFIDENCE]: 'ReportSection12cd800f5b41b0e04f27',
    [OPERATING_MODEL]: 'ReportSectionfa523654a26976a87575',
    [TALENTS_AND_CULTURES]: 'ReportSectiond6f33ab2a21e9e19a769',
  };

  Object.keys(window.report).forEach(async reportName => {
    const report = await window.report[reportName];
    const reportActivePage = await report.getActivePage();

    if (reportActivePage.name !== DEFAULT_PAGES[reportName]) {
      await window.report[reportName].setPage(DEFAULT_PAGES[reportName]);
    }
  });
};

export default function useReportRendering() {
  const dispatch = useDispatch();
  const biFilters = useSelector(reportFiltersSelector);

  useLayoutEffect(() => {
    (async () => {
      await syncHzaToTdpFilters();
      if (location.pathname === '/landingpage') {
        return hideAllReports();
      }
      if (location.pathname === '/moodandconfidence') {
        await checkElement(MOOD_AND_CONFIDENCE, callback);
      }

      if (location.pathname === '/engineeringcompetitivness/operating-model') {
        await checkElement(OPERATING_MODEL, callback);
      }

      if (location.pathname === '/engineeringcompetitivness/talents-and-culture') {
        await checkElement(TALENTS_AND_CULTURES, callback);
      }

      await setReportDefaultPage();
    })();

    return () => hideAllReports();
  }, [location.pathname]);

  useEffect(() => {
    if (biFilters) {
      syncFiltersToHza(biFilters);
    }
  }, [biFilters]);

  const syncHzaToTdpFilters = async hzaFilters => {
    window.addEventListener('message', event => {
      if (event.data.type === 'SYNC_FILTERS') {
        dispatch(setReportFilters(event.data.filters));
        Object.keys(window.report).forEach(async reportName => {
          await syncTdpFilters(reportName, event.data.filters);
        });
      }
    });
  };

  /**
   * Check if the report iframe exists and is loaded
   *
   * @param reportName string
   * @param callback function
   */
  const checkElement = async (reportName, callback) => {
    const interval = setInterval(() => {
      const id = `${reportName}-report`;
      if (document.getElementById(id)) {
        clearInterval(interval);
        callback(document.getElementById(id), reportName);
      }
    });
  };

  /**
   * Display current powerBi report page
   *
   * @param iframe HtmlNode
   * @param reportName string
   */
  const callback = async (iframe, reportName) => {
    iframe.classList.remove('hidden');
    iframe.classList.add('block');

    if (biFilters) {
      await syncTdpFilters(reportName);
    }
  };

  /**
   * Automatic set the tdp/hza filters for all hidden report
   *
   * @param reportName string
   * @param hzaFilters
   * @returns {Promise<void>}
   */
  const syncTdpFilters = async (reportName, hzaFilters = null) => {
    const report = window.report[reportName];
    const reportFilters = await report.getFilters();

    if (hzaFilters) {
      [...hzaFilters].forEach((filter, index) => {
        filter.target = reportFilters[index].target;
        return filter;
      });
      return await report.updateFilters(FiltersOperations.Replace, hzaFilters);
    }

    [...biFilters].forEach((filter, index) => {
      filter.target = reportFilters[index].target;
      return filter;
    });

    await report.updateFilters(FiltersOperations.Replace, biFilters);
  };

  /**
   * Post current tdp filters to hza iframe
   * @param currentFilters
   */
  const syncFiltersToHza = currentFilters => {
    const hzaIframe = document.getElementById(HZA_IFRAME_ID).contentWindow;
    hzaIframe.postMessage({ type: 'SYNC_FILTERS', filters: currentFilters }, '*');
  };

  /**
   * Hide all powerBi report pages ad revert them back to the coresponding default page
   */
  const hideAllReports = async () => {
    for (const element of document.querySelectorAll('.bi-report-container')) {
      element.classList.remove('block');
      element.classList.add('hidden');
    }
  };
}
