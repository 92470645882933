export const periodOptions = [
  '1 Month',
  '2 Months',
  '3 Months',
  '4 Months',
  '5 Months',
  '6 Months',
  '7 Months',
  '8 Months',
  '9 Months',
  '10 Months',
  '11 Months',
  '12 Months',
  '24 Months',
];

export const dateOptions = [
  'May 24',
  'Apr 24',
  'Mar 24',
  'Feb 24',
  'Jan 24',
  'Dec 23',
  'Nov 23',
  'Oct 23',
  'Sep 23',
  'Aug 23',
  'Jul 23',
  'Jun 23',
  'May 23',
  'Apr 23',
  'Mar 23',
  'Feb 23',
  'Jan 23',
  'Dec 22',
  'Nov 22',
  'Oct 22',
  'Sep 22',
];
