import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useChartUtils from './useChartUtils';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import { getOrganizationsIndicatorsApi } from '../../../redux/api';
import { selectedGeneralFilters } from '../../../../../components/EmwGeneralFilters/redux/selectors';
import {
  generateDateOptionsEngineeringCompliance,
  getFiltersPayload,
} from '../../../../../components/EmwGeneralFilters/utils';
import { filterCCTypeSelector } from '../../../../organization/redux/selectors';

export default function useEngineeringCompliance() {
  const chartUtils = useChartUtils();
  const snackbar = useAppSnackbar();
  const selectedFilters = useSelector(selectedGeneralFilters);
  const selectedCCTypes = useSelector(filterCCTypeSelector);

  const [stateData, setStateData] = useState({
    organizationFilters: getFiltersPayload(selectedFilters),
    periodFilters: {
      period: '12 Months',
      date: generateDateOptionsEngineeringCompliance(21)[0],
    },
    ccTypes: selectedCCTypes,
  });

  const [chartsData, setChartsData] = useState({
    emHoeos: [],
    disciplines: [],
    allComponents: [],
    referents: [],
    allReferents: [],
    hodsNoJfOoEm: [],
  });

  useEffect(() => {
    setStateData({
      ...stateData,
      organizationFilters: getFiltersPayload(selectedFilters),
    });
  }, [selectedFilters]);

  useEffect(() => {
    setStateData({
      ...stateData,
      ccTypes: selectedCCTypes,
    });
  }, [selectedCCTypes]);

  useEffect(() => {
    (async () => {
      await fetchIndicators(stateData);
    })();
  }, [stateData]);

  const fetchIndicators = async payload => {
    const response = await getOrganizationsIndicatorsApi(payload);
    if (response.data) {
      transformData(response.data);
    } else {
      snackbar.show(response.message || response.statusText, 'warning');
    }
  };

  /**
   * transform server response to a chart data type
   * @param data
   */
  const transformData = data => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentQuarter = chartUtils.getQuarter(date);
    const emHoeos = chartUtils.formatData(data.emHoeos, currentQuarter, currentYear);
    const disciplines = chartUtils.formatData(data.disciplines, currentQuarter, currentYear);
    const allComponents = chartUtils.formatData(data.allComponents, currentQuarter, currentYear);
    const referents = chartUtils.formatData(data.referents, currentQuarter, currentYear);
    const hodsNoJfOoEm = chartUtils.formatData(data.hodsNoJfOoEm, currentQuarter, currentYear);
    const allReferents = Object.keys(data.allReferents).map(groupKey => {
      const group = {};
      group.label = chartUtils.mapLabel(groupKey);

      Object.keys(data.allReferents[groupKey]).forEach((key, index) => {
        if (key === 'quartersToRetrieveData') {
          return;
        }
        const barKey = data.allReferents[groupKey].quartersToRetrieveData[index];
        group[barKey] = data.allReferents[groupKey][key];
      });

      return group;
    });

    setChartsData(currentData => ({
      ...currentData,
      emHoeos,
      disciplines,
      allComponents,
      referents,
      allReferents,
      hodsNoJfOoEm,
    }));
  };

  return {
    chartsData,
    stateData,
    setStateData,
  };
}
