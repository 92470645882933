import React from 'react';
import { Grid } from '@mui/material';
import useEnv from '../../../../hooks/useEnv';
import MoodAndConfidenceSVG from '../../../../../styles/images/MoodAndConfidence.svg';
import CardItem from './CardItem';
import SkillsSVG from '../../../../../styles/images/Skills.svg';
import EngineeringOrganizationSVG from '../../../../../styles/images/EngineeringOrganization.png';
import EngineeringCompetitivenessSVG from '../../../../../styles/images/EngineeringCompetitiveness.svg';

export default function CardList() {
  const env = useEnv();

  return (
    <Grid container className="cardList" alignItems="center" justifyContent="space-evenly">
      <Grid item sm={12} md={'auto'} mr={'10px'} sx={{ height: '520px' }}>
        <CardItem
          id="moodAndConfidence"
          headerImg={MoodAndConfidenceSVG}
          title="Mood & Confidence to deliver Focus"
          subTitle="Focus on Mood & Confidence to deliver KPI’s"
          hoverText="The EMW provides a section focusing on the “Mood” & “Confidence to deliver” KPI’s that are based on the answers to the Mood & Confidence to deliver survey that is periodically sent to all Thales engineers. It also provides analysis tools for these KPI’s.
                    Note: “Mood” & “Confidence to deliver” KPI’s are also part of the Engineering Competitiveness KPI’s."
          path="/moodandconfidence"
          env={env}
        />
      </Grid>
      {/*<Grid item sm={12} md={"auto"} mr={"10px"} sx={{ height: "520px" }}>*/}
      {/*  <CardItem*/}
      {/*    id="jobFamilyFocus"*/}
      {/*    headerImg={JobFamilyFocus}*/}
      {/*    title="Job Family Focus"*/}
      {/*    subTitle="Navigate to the Job Family Focus"*/}
      {/*    hoverText="The EMW provides a section focusing on the Job Family Groups, Job Families and Key Profiles distribution and evolution across the*/}
      {/*              Engineering organization. The transformation from the old to the new Job Families is also covered."*/}
      {/*    path="/jobfamily"*/}
      {/*    env={env}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item sm={12} md={'auto'} mr={'10px'} sx={{ height: '520px' }}>
        <CardItem
          id="enineeringCompetitiveness"
          headerImg={EngineeringCompetitivenessSVG}
          title="Engineering Cockpit"
          subTitle="Browse through Engineering Competitiveness KPI’s"
          hoverText="The EMW provides a section dedicated to Engineering Competitiveness KPI’s that are managed in a set of dashboards, in compliance with the Chorus 2.0 instruction Ref. 87216428-DDQ-GRP-EN-001.
                    Note: The construction of this section is still under construction and is progressively completed as the source data is made available."
          path={'/engineeringcompetitivness/operating-model'}
          env={env}
        />
      </Grid>
      {/*<Grid item sm={12} md={"auto"} mr={"10px"} sx={{ height: "520px" }}>*/}
      {/*  <CardItem*/}
      {/*    id="skills"*/}
      {/*    headerImg={SkillsSVG}*/}
      {/*    title="Skills Focus"*/}
      {/*    subTitle="Focus on Skills KPI's"*/}
      {/*    hoverText="The EMW provides a section focusing on the Skills KPI’s that are based on skills assessments data from the Impact Tool. It also provides analysis tools for these KPI’s. Note: Skills KPI’s are also part of the Engineering Competitiveness KPI’s."*/}
      {/*    path={"/skills"}*/}
      {/*    env={env}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item sm={12} md={'auto'} mr={'10px'} sx={{ height: '520px' }}>
        <CardItem
          id="engineeringOrganisation"
          headerImg={EngineeringOrganizationSVG}
          title="Engineering Organization"
          subTitle="Navigate through the Engineering Organization"
          hoverText="The EMW provides a section to navigate through the Thales Engineering Organization, from Corporate Engineering to Competence Centers.
                    Note: The EMW is based on the displayed Engineering organization for managing its data, services and access rights."
          path="/organization"
          env={env}
        />
      </Grid>
    </Grid>
  );
}
