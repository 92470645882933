import React from 'react';

export default function useChartUtils({ colorMapping = {} }) {
  const groupColors = ['#0F186E', '#6871BF', '#126836', '#178244', '#F3C999', '#E69433', '#E07900'];
  const margin = { top: 10, right: 10, bottom: 35, left: 35 };
  const indexScale = { type: 'band', round: true };
  const axisLeft = unit => {
    return {
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legendPosition: 'middle',
      legendOffset: -40,
      ticksPosition: 'after',
      format: (value, index) => {
        if (index === 0) {
          return value;
        }
        return `${value}${unit}`;
      },
    };
  };

  const groupsOrder = [
    'Data Ref',
    'Cybersecurity',
    'Ecodesign',
    'UX Design',
    'Data Protection',
    'Make Or buy footprint',
    'Engineering Quality',
  ];

  const sortQuarters = (a, b) => {
    let [quarterA, yearA] = a.split(' ');
    let [quarterB, yearB] = b.split(' ');

    quarterA = parseInt(quarterA.substring(1));
    yearA = parseInt(yearA);
    quarterB = parseInt(quarterB.substring(1));
    yearB = parseInt(yearB);

    if (yearA === yearB) {
      return quarterA - quarterB;
    } else {
      return yearA - yearB;
    }
  };

  const formatKey = (key, currentQuarter, currentYear) => {
    let tmpDate = new Date();
    if (key === 'currentQ') {
      tmpDate = new Date();
    } else if (key === 'qminusOne') {
      tmpDate.setMonth(tmpDate.getMonth() - 3);
    } else if (key === 'qminusTwo') {
      tmpDate.setMonth(tmpDate.getMonth() - 6);
    }
    if (key === 'qminusThree') {
      tmpDate.setMonth(tmpDate.getMonth() - 9);
    }
    return `Q${getQuarter(tmpDate)} ${tmpDate.getFullYear()}`;
  };

  const formatData = (chartData, currentQuarter, currentYear) => {
    return Object.keys(chartData)
      .map(key => {
        const barData = {
          label: formatKey(key, currentQuarter, currentYear),
          value: chartData[key],
        };

        return barData;
      })
      .reverse();
  };

  const sortGroups = (a, b) => {
    // Get the index of the labels in the 'order' array
    const indexA = groupsOrder.indexOf(a.label);
    const indexB = groupsOrder.indexOf(b.label);

    // Compare these indices to determine order
    return indexA - indexB;
  };

  const mapLabel = key => {
    const labels = {
      dr: 'Data Ref',
      cybsr: 'Cybersecurity',
      ecodr: 'Ecodesign',
      uxdr: 'UX Design',
      dp: 'Data Protection',
      mobfr: 'Make Or buy footprint',
      eql: 'Engineering Quality',
    };
    return labels[key];
  };

  function getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  /**
   * Generate the chart bar colors based on colorMapping
   * colorMapping = {
   *     key: string|number
   *     color: string
   * }
   */
  const chartColors = () => {
    return {
      defs: () => {
        return Object.keys(colorMapping).map(key => ({
          id: key,
          type: 'patternLines',
          background: 'inherit',
          color: colorMapping[key].color,
          spacing: 0.1,
        }));
      },
      fill: () => {
        return Object.keys(colorMapping).map(key => ({
          match: {
            id: key,
          },
          id: key,
        }));
      },
    };
  };

  return {
    groupColors,
    indexScale,
    margin,
    axisLeft,
    sortQuarters,
    formatKey,
    formatData,
    sortGroups,
    mapLabel,
    getQuarter,
    chartColors,
  };
}
