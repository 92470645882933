import * as constants from '../constants';
import { createAction } from '@reduxjs/toolkit';

export const FILTERS = `${constants.MODULE_NAME}/FILTERS`;
export const SELECTED_FILTERS = `${constants.MODULE_NAME}/SELECTED_FILTERS`;
export const CLEAR_ALL_FILTERS = `${constants.MODULE_NAME}/CLEAR_ALL_FILTERS`;
export const AVAILABLE_FILTERS = `${constants.MODULE_NAME}/AVAILABLE_FILTERS`;
export const LOADING = `${constants.MODULE_NAME}/LOADING`;

export const setGeneralFilters = createAction(FILTERS);
export const setSelectedFilters = createAction(SELECTED_FILTERS);
export const clearAllFilters = createAction(CLEAR_ALL_FILTERS);
export const setAvailableFilters = createAction(AVAILABLE_FILTERS);
export const setLoading = createAction(LOADING);
