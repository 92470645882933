import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchOptions, computeURL, fetch } from './../../util/fetch';
import { TdsTypography } from '@tds/react';
import { NO_RESOURCE_ALLOCATED } from '../../V2/features/organization/constants';
import EmwTypography from '../../V2/components/EmwTypography/EmwTypography';
import { perspectiveSelector } from '../../V2/features/organization/redux/selectors';
import { Popper } from '@mui/material';
import CustomPopper from '../../V2/components/CustomPopper/CustomPopper';

const defaultStyle = {
  backgroundColor: 'white',
  width: '100%',
  '*': {
    padding: 0 + '!important',
  },
  input: {
    fontSize: '12px',
    height: '20px',
    padding: '4px!important',
    border: '1px solid #5D607E',
    borderRadius: '2px',
  },
  '.MuiInputBase-sizeSmall': {
    borderRadius: '2px',
    padding: 0,
  },
};

const Loading = () => {
  return (
    <div className="flex items-center">
      <EmwTypography fontSize={6} classes="ml-xxs">
        Loading ...
      </EmwTypography>
    </div>
  );
};

export default function EmwAutocomplete(props) {
  const {
    data,
    placeHolder,
    additionalCSS,
    variant,
    size,
    onAutocompleteChange,
    disabled,
    defaultValue,
    urlToFetch,
    inputId,
    calculateAutocompleteSugestion,
    calculateAutocompleteLabel,
    autoFocus,
    disableClearable,
    onInputChange,
    orgId,
    placement,
    isSearchHeader,
  } = props;
  const [filterAutocomplete, setFilterAutocomplete] = useState('noFilter');
  const [throttledSearch, setThrottledSearch] = useState();
  const [suggestions, setSuggestions] = useState([{ label: '', value: '', object: {} }]);
  const [val, setVal] = useState({});
  const [shouldSearch, setShouldSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const perspective = useSelector(perspectiveSelector);
  let selectedValue = null;

  useEffect(() => {
    if (typeof data === 'undefined') {
      if (typeof throttledSearch === 'undefined') {
        setThrottledSearch(startTimeoutForSearch(filterAutocomplete));
      } else {
        clearTimeout(throttledSearch);
        setThrottledSearch(startTimeoutForSearch(filterAutocomplete));
      }
    } else {
      if (filterAutocomplete === 'noFilter') {
        const selectedObject = data.filter(function (element) {
          return element.value === (defaultValue ? defaultValue.value : null);
        });
        if (selectedObject.length > 0) {
          setVal({ label: selectedObject[0].label });
        }
      }
    }
  }, [filterAutocomplete]);

  useEffect(() => {
    if (perspective) {
      setVal({});
    }
  }, [perspective]);

  const startTimeoutForSearch = filterAutocompleteP => {
    return setTimeout(function () {
      if (shouldSearch && !disabled) {
        let urlParams = '?val=' + filterAutocompleteP;

        if (orgId) {
          urlParams += '&orgId=' + orgId;
        }

        fetchData(filterAutocompleteP, urlParams);
      } else if (
        filterAutocompleteP === 'noFilter' &&
        typeof data === 'undefined' &&
        selectedValue == null
      ) {
        setVal({ label: defaultValue });
        selectedValue = defaultValue;
      }
    }, 1000);
  };

  const fetchData = (filterAutocompleteP, urlParams) => {
    setIsLoading(true);

    return fetch(computeURL(urlToFetch, null, null, null, urlParams), fetchOptions())
      .then(responseData => {
        const newObject = responseData.data
          ? responseData.data.map(function (value) {
              return {
                label: calculateAutocompleteSugestion(value),
                value: value.id,
                object: value,
              };
            })
          : [{ label: '', value: '', object: {} }];
        setSuggestions(newObject);
        if (
          filterAutocompleteP === 'noFilter' &&
          typeof data === 'undefined' &&
          selectedValue == null
        ) {
          setVal({ label: defaultValue });
          selectedValue = defaultValue;
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (event, value) => {
    setVal({ label: value ? calculateAutocompleteLabel(value) : '' });
    if (typeof onAutocompleteChange === 'function') {
      if (value && value.object) {
        value.label = calculateAutocompleteLabel(value);
      }
      onAutocompleteChange(value, inputId);
    }
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === 'reset' && isSearchHeader) {
      setVal('');
    }
    if (value !== '') {
      if (event) {
        setShouldSearch(true);
      }
      setFilterAutocomplete(value);
    }

    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleFilterOptions = (options, state) => {
    return options.filter(item => {
      if (item.hasOwnProperty('label') && Array.isArray(item.label)) {
        let found = false;
        const searchParts = state.inputValue.split(' ');

        if (Array.isArray(searchParts)) {
          for (let i = 0; i <= searchParts.length; i++) {
            if (searchParts[i]) {
              const searchPartLowercase = searchParts[i].toLowerCase();
              const isPartInLabel = item.label.some(label =>
                label.toLowerCase().includes(searchPartLowercase)
              );
              if (isPartInLabel) {
                found = true;
                break;
              }
            }
          }
        }

        return found;
      }
    });
  };

  const getOptionLabel = option => {
    return option.label ? option.label + '' : '';
  };

  const renderOption = (props, option) => {
    if (!option.label) {
      return '';
    }
    props['aria-selected'] = false;
    if (Array.isArray(option.label)) {
      return (
        <li {...props} className={`flex-col items-start ${props.className}`}>
          {option.label.map((label, index) => {
            return <div>{label}</div>;
          })}
        </li>
      );
    }

    return '';
  };

  const isOptionEqualToValue = (option, value) => {
    const val1 = value ? value.label : '';
    return typeof val1 === 'undefined' || option.label === '' || option.label === val1;
  };

  const renderInput = params => {
    return (
      <TextField
        error={!params.inputProps.value && placeHolder === NO_RESOURCE_ALLOCATED ? true : false}
        {...params}
        size={size}
        autoFocus={autoFocus}
        variant={variant}
        placeholder={placeHolder}
      />
    );
  };

  if (disabled) {
    return <TdsTypography component={'caption-1'}>{placeHolder}</TdsTypography>;
  }

  return (
    <Autocomplete
      size="small"
      autoHighlight
      loading={isLoading}
      loadingText={<Loading />}
      value={val && val !== {} ? val : ''}
      id={inputId}
      sx={{ ...defaultStyle, ...additionalCSS }}
      disabled={typeof disabled !== 'undefined' ? disabled : false}
      defaultValue={typeof data !== 'undefined' ? defaultValue : null}
      disableClearable={disableClearable ? disableClearable : false}
      options={data ? data : suggestions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOptions={handleFilterOptions}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={renderInput}
      PopperComponent={CustomPopper}
    />
  );
}
