import React from 'react';
import { styled } from '@mui/material/styles';
import HzaContextProvider from '../../V2/features/hzaSync/context/HzaContext';
import Header from '../../V2/features/header/components/Header';
import PowerBi from '../../V2/features/powerBi/components/PowerBi';
import Footer from '../../V2/features/footer/components/Footer';
import FeedbackButton from '../../V2/features/feedback/FeedbackButton';
import clx from 'classnames';
import { useSelector } from 'react-redux';
import { feedbackInsideModalSelector, feedbackStatusSelector } from '../../V2/features/feedback/redux/selectors';

const Wrapper = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  '&.wrapperDisabled *:not(.feedback-form *)': {
    zIndex: 0,
  },
}));

const Main = styled('main')(() => ({
  display: 'flex',
  backgroundColor: 'white!important',
  overflow: 'auto!important',
  flexGrow: 1,
  flexDirection: 'column',
}));

export default function DefaultLayout({ children }) {
  const isFeedback = useSelector(feedbackStatusSelector);
  const feedbackInsideModal = useSelector(feedbackInsideModalSelector)
  const wrapperClassName = clx('wrapper', {
    wrapperDisabled: isFeedback,
  });

  return (
    <HzaContextProvider>
      <Wrapper className={wrapperClassName} id="wrapper">
        <Header />
        <Main className="main" id="main">
          {children}
          <PowerBi />
          {/* Make sure the PowerBi component is always loaded through out the whole app */}
        </Main>
        <Footer />
       {!feedbackInsideModal && <FeedbackButton />} 
      </Wrapper>
    </HzaContextProvider>
  );
}
