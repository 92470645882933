const initialProps = { list: {}, error: null, loading: false };

const reduceAction = (state, action, initialData) => {
  if (!action.meta) {
    return {
      ...state,
      [action.type]: action.payload,
    };
  }
  if (action.meta.fetching) {
    return {
      ...state,
      [action.type]: {
        ...initialData,
        loading: true,
      },
    };
  }

  const result = {
    ...state,
    [action.type]: {
      list: action.error ? initialData.list : action.payload,
      error: action.error ? action.payload : null,
      loading: action.meta.fetching,
    },
  };

  if (action.meta.requestParams) {
    result[action.type].requestParams = action.meta.requestParams;
  }

  return result;
};

const getState = (state, action, initialState, reducerConfig) => {
  const reduceThisAction = reducerConfig[action.type];

  if (typeof reduceThisAction === 'function') {
    const initialData = initialState[action.type];
    return reduceThisAction(state, action, initialData);
  }

  return state;
};

export { getState, initialProps, reduceAction };
