import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation } from 'react-router-dom-v5-compat';
import { setIsDrilled, setSelectedReport } from '../../features/powerBi/redux/actions';
import EMWLogo from '../../../styles/images/icon/EMW_logo-01.svg';
import { StyledEmwLogo } from './styled';
import EmwHeaderBrand from '../EmwHeaderBrand/EmwHeaderBrand';
import { setIsHza } from '../../features/footer/redux/actions';
import { biPropsSelector } from '../../features/header/redux/selectors';

export default function EmwBrand() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleClick = () => {
    dispatch(setSelectedReport(null));
    dispatch(setIsDrilled(false));
    dispatch(setIsHza(false));
    history('/landingpage');
  };

  return (
    <EmwHeaderBrand>
      <StyledEmwLogo src={EMWLogo} alt="EMW logo" onClick={handleClick} id="goHome" />
    </EmwHeaderBrand>
  );
}
