import React, { useEffect, useState } from 'react';
import UsageMonitoringDrillToDetail from './UsageMonitoringDrillToDetail';
import UsageMonitoringGraph from './UsageMonitoringGraph';
import { getUsageMonitoringIndicatorsApi } from '../../../redux/api';
import TimeFrame from '../../../../../components/EmwCharts/components/Filters/components/TimeFrame/TimeFrame';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import { StyledUsageMonitoring } from '../styled';
import EmwButton from '../../../../../components/EmwButton/EmwButton';
import EmwIcon from '../../../../../components/EmwIcon/EmwIcon';
import GeneralFiltersContainer from '../../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectedGeneralFilters } from '../../../../../components/EmwGeneralFilters/redux/selectors';
import { FILTERS_SECTIONS } from '../../../../../components/EmwGeneralFilters/constants';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import { setLoading } from '../../../../../components/EmwGeneralFilters/redux/actions';
import moment from 'moment';
import { getFiltersPayload } from '../../../../../components/EmwGeneralFilters/utils';

export default function UsageMonitoring() {
  const [activeUsersData, setActiveUsersData] = useState([]);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const dates = [];
  const generateDateOptions = months => {
    for (let i = 0; i < months; i++) {
      const date = moment().subtract(i, 'months');
      dates.push(date.format('MMM YY'));
    }
    return dates;
  };
  const [stateData, setStateData] = useState({
    organizationFilters: getFiltersPayload(selectedFilters),
    periodFilters: {
      period: '12 Months',
      date: generateDateOptions(21)[0],
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
    window.location.hash = "drill"
    };
  const handleClickBack = () => {
    setIsOpen(!isOpen);
       window.location.hash = ""
  };

  useEffect(() => {
    setStateData(currentState => {
      return {
        ...currentState,
        organizationFilters: getFiltersPayload(selectedFilters),
      };
    });
  }, [selectedFilters]);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));

      try {
        const response = await getUsageMonitoringIndicatorsApi(stateData);
        setActiveUsersData(response.data);
      } catch (reason) {
        snackbar.show(reason.message || reason.statusText, 'warning');
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [stateData]);

  return (
    <GeneralFiltersContainer>
      <div
        className="flex flex-col flex-1 overflow-auto px-xs pb-xs"
        style={{ backgroundColor: '#F7F7F9' }}
      >
        <div className="title-and-period flex mb-m items-center">
          {isOpen ? (
            <StyledUsageMonitoring>
              <div className="back flex items-center">
                <div className="flex justify-center items-center">
                  <EmwButton
                    variant="outlined"
                    title="Drill to detail"
                    id="drillToDetail"
                    onClick={handleClickBack}
                  >
                    <EmwIcon
                      icon="arrow_back_ios"
                      variant="outlined"
                      size="xsmall"
                      classes="pl-l"
                    />
                    <EmwTypography fontSize="18" classes="text-black-100">
                      Back
                    </EmwTypography>
                  </EmwButton>
                </div>
                <EmwTypography classes="text-primary-500" fontSize={5} fontWeight="bold">
                  Usage Monitoring - drill to detail
                </EmwTypography>
              </div>
            </StyledUsageMonitoring>
          ) : (
            <EmwTypography classes="text-primary-500" fontSize={4} fontWeight="bold">
              Usage Monitoring
            </EmwTypography>
          )}
          <div className="ml-auto">
            <TimeFrame
              filters={stateData}
              setFilters={setStateData}
              setActiveUsersData={setActiveUsersData}
              isUsageMonitoring={true}
            />
          </div>
        </div>

        {isOpen ? (
          <UsageMonitoringDrillToDetail
            payload={stateData}
            handleClickBack={handleClickBack}
            stateData={stateData}
          />
        ) : (
          <UsageMonitoringGraph activeUsersData={activeUsersData} handleClick={handleClick} />
        )}
      </div>
    </GeneralFiltersContainer>
  );
}
